import request from '@/api/request.js'
// 发布内容统计(APP统计页面)
export function statistics(data) {
    return request({
        url:'module-polyse/publishContent/statistics',
        method:'post',
        data
    })
}
// 有害信息统计(APP统计页面)
export function harmfulData(data) {
    return request({
        url:"module-listener-complex/harmfulData/statistics",
        method:'post',
        data
    })
}
// 预警报告页面错误表述分组统计
export function getPlanMisreGroups(data) {
    return request({
        url:"module-web/statistics/getPlanMisreGroups",
        method:'post',
        data
    })
}
// 预警报告页面季度系统监测
export function getReportDataInfo(data) {
    return request({
        url:'module-web/statistics/getReportDataInfo',
        method:"post",
        data
    })
}

// 有害信息详情
export function detail(detail) {
    return request({
        url: `module-listener-complex/harmfulData/detail/${detail}`,
        method: "get"
    })
}