//问题平台
import request from '../request.js';
// 列表
export function queryDisposeRecordList(data) {
    return request({
        url:"module-web/abnormalPlatform/queryDisposeRecordList",
        method:'post',
        data
    })
}
// 添加异常平台
export function  addAbnormalPlatform(data) {
    return request({
        url:'module-web/abnormalPlatform/addAbnormalPlatform',
        method:'post',
        data
    })
}
// 开展处置
export function carryOut(data) {
    return request({
        url:"module-web/abnormalPlatform/carryOut",
        method:'put',
        data
    })
}
// 处置完成
export function disposeComplete(data) {
    return request({
        url:"module-web/abnormalPlatform/disposeComplete",
        method:'put',
        data
    })
}
// 误判调整
export function misjudgmentAdjustment(recordId) {
    return request({
        url:`module-web/abnormalPlatform/misjudgmentAdjustment/${recordId}`,
        method:'get'
    })
}
// 查询处置已完成的总数
export const count = (data) => request({url:"module-account/account/count",data,method:"post"})
