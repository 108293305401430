<template>
  <!-- 手机端要的 -->
  <!-- 属地监测报告 -->
  <!-- 背景图 -->
  <div class="report">
    <!-- 头部 -->
    <header>
      <!-- logo -->
      <img class="logo-img" src="@/assets/new/logo.png" alt="" /> <br />
      <!-- title -->
      <img class="title-img" src="@/assets/new/title.png" alt="" /> <br />
      <!-- 信息 || 时间 -->
      <div class="desc-Box">
        <span class="desc">{{ monitorObj.orgName }}</span> <br />
      </div>
      <br />
      <!-- 黄色的选中框 -->

      <div class="selectBox" :style="{ width: fontWidth('本季度系统监测') }">
        {{ `${getMessageType()}系统监测` }}
      </div>
    </header>
    <!-- 平台 || 账号 || 数据 -->
    <div class="nav-box">
      <div class="nav-item">
        <img src="@/assets/new/pintai.png" alt="" srcset="" />
        <span class="num">{{ monitorObj.platformTypeCount }}个</span>
        <span class="desc">平台</span>
      </div>
      <div class="nav-item">
        <img src="@/assets/new/zhanghao.png" alt="" srcset="" />
        <span class="num">{{ywcCount}}个</span>
        <span class="desc">账号</span>
      </div>
      <div class="nav-item">
        <img src="@/assets/new/shuju.png" alt="" srcset="" />
        <span class="num">{{ fbTotal }}个</span>
        <span class="desc">数据</span>
      </div>
    </div>
    <!-- tab 展示4个 -->
    <div class="tab-box">
      <div class="tab-item">
        <span class="num">{{ monitorObj.planMonitorCount }}</span
        ><br />
        <span class="desc">监测方案</span>
      </div>
      <div class="tab-item">
        <span class="num">{{ monitorObj.planMonitorDataCount }}</span
        ><br />
        <span class="desc">监测信息</span>
      </div>
      <div class="tab-item">
        <span class="num">{{ harmfulObj.total }}</span
        ><br />
        <span class="desc">有害信息</span>
      </div>
      <div class="tab-item">
        <span class="num">{{ monitorObj.planMisreDataCount }}</span
        ><br />
        <span class="desc">错误表述</span>
      </div>
    </div>
    <!-- 平台及账号发布信息数量情况 -->
    <div class="echarts-box">
      <div
        class="selectBox echarts-absolute"
        :style="{ width: fontWidth('平台及账号发布信息数量情况') }"
      >
        平台及账号发布信息数量情况
      </div>
      <div class="echarts-show" id="FBecharts" v-loading="ifpublishObj"></div>
      <!-- 错误表述位图 -->
      <Bitmap :desc="'发布信息'" v-if="!publishObj"></Bitmap>
    </div>
    <!-- 有害信息 -->
    <div class="echarts-box">
      <div
        class="selectBox echarts-absolute"
        :style="{ width: fontWidth('有害信息') }"
      >
        有害信息
      </div>
      <div v-loading="ifharmfulObj">
        <!-- nav -->
        <div class="echarts-nav">
          <div>
            发现<span class="num">{{ harmfulObj.total }}</span
            >个
          </div>
          <div>
            已处理<span class="num">{{ harmfulObj.processed }}</span
            >个
          </div>
          <div>
            未处理<span class="num">{{ harmfulObj.unprocessed }}</span
            >个
          </div>
        </div>
        <div class="echarts-show" id="YHecharts"></div>
        <div class="title" style="margin-top: 10px">有害问题类型TOP3</div>
        <!-- tag -->
        <div class="tagBox">
          <div v-for="(v, i) in harmfulObj.distributionTop" :key="i">
            {{ v }}
          </div>
        </div>
      </div>
      <Bitmap :desc="'有害信息'" v-if="!harmfulObj"></Bitmap>
    </div>
    <!-- 错误表述 -->
    <div class="echarts-box">
      <div
        class="selectBox echarts-absolute"
        :style="{ width: fontWidth('错误表述') }"
      >
        错误表述
      </div>
      <!-- 标题 -->
      <div class="title">
        发现问题文章数量<span class="num">{{ wrongObj.total }}</span
        >个
      </div>
      <!-- nav -->
      <div class="echarts-nav">
        <div>
          已研判<span class="num">{{ wrongObj.manCount }}</span
          >个
        </div>
        <div>
          未研判<span class="num">{{ wrongObj.machineCount }}</span
          >个
        </div>
      </div>
      <!-- echarts -->
      <div
        class="echarts-show m-t-16"
        id="CWecharts"
        v-loading="ifwrongObj"
      ></div>
      <!-- 错误表述位图 -->
      <Bitmap :desc="'错误表述'" v-if="!wrongObj"></Bitmap>
    </div>
  </div>
</template>

<script>
import Bitmap from "../../components/bitmap.vue";
import * as echarts from "echarts";
import {
  statistics,
  harmfulData,
  getPlanMisreGroups,
  getReportDataInfo,
} from "../../api/shift/shift";
import { count } from "../../api/Problem/index.js";
export default {
  components: {
    Bitmap,
  },
  data() {
    return {
      publishObj: { total: 0 }, // 发布内容
      fbTotal: 0, // 发布内容总数
      ifpublishObj: true, // 发布内容加载
      harmfulObj: {}, // 有害信息
      ifharmfulObj: true, // 有害信息加载
      wrongObj: {}, // 错误表述
      ifwrongObj: true, //错误表述加载
      monitorObj: {}, // 监测信息
      ywcCount:null
    };
  },
  methods: {
    getMessageType() {
      const obj = {
        1:'本日',
        2:'本周',
        3:'本月度',
        4:'本季度',
        5:'本年度'
      }
      return obj[this.$route.query.messageType]
    },
    async count() {
      const Res = await count(
        {} 
        )
        if (Res&& Res.data && Res.data.code == 200) {
        this.ywcCount = Res.data.data
      }
    },
    fontWidth(font) {
      return font.length * 22 + "px";
    },
    // 发布内容统计(APP统计页面)
    async statistics() {
      const { endTime, startTime } = this;
      try {
        
        const Res = await statistics({
          endTime,
          startTime,
        });
        if (Res.data.code == 200) {
          const {
            data: { data },
          } = Res;
          this.publishObj = data;
          this.fbTotal = data.total;
          this.ifpublishObj = false;
          const values = data.distribution.map((v) => v.value); // x轴文字
          const nums = data.distribution.map((v) => v.num); // 柱子的数量
          this.statisticsEchart({ values, nums }, "FBecharts"); // 发布内容charts图
        }
        setTimeout(() => {
          this.ifpublishObj = false;
        }, 1000);
      } catch {
        this.publishObj = null;
        this.ifpublishObj = false;
      }
    },
    // 发布内容charts图 || 错误表述echarts图
    statisticsEchart({ values, nums } = {}, echartsName) {
      let myChart = echarts.init(document.getElementById(echartsName));
      const option = {
        //你的代码
        tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
        grid: {
          left: "0%",
          right: "10%",
          top: "4%",
          bottom: "4%",
          containLabel: true,
        },
        yAxis: {
          nameTextStyle: { fontSize: 20, color: "#fff" },
          type: "value",
          splitLine: { show: false },
          axisLine: { show: false, textStyle: { color: "#1ebfda" } },
        },
        xAxis: {
          type: "category",
          data: values,
          axisLine: {
            show: true,
            textStyle: {
              color: "#1ebfda",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: -40,
            interval: 0,
            show: true,
          },
        },
        series: {
          type: "bar",
          barWidth: 10,
          data: nums,
          itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
          },
          color: "#5C9EFF",
        },
      };
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    // 有害信息
    async harmfulData() {
      const { endTime, startTime } = this;
      try {
        const Res = await harmfulData({ endTime, startTime });
        if (Res.data.code == 200) {
          const {
            data: { data },
          } = Res;
          this.ifharmfulObj = false;
          if (!data) {
            this.harmfulObj = null;
            return;
          }
          this.harmfulObj = data;
          this.harmfulEcharts(
            data.distribution.map((v) => ({ name: v.value, value: v.num }))
          );
        } else {
          this.harmfulObj = null;
          this.ifharmfulObj = false;
        }
      } catch (error) {
        this.harmfulObj = null;
        this.ifharmfulObj = false;
      }
    },
    // 有害信息echarts 图
    harmfulEcharts(datas) {
      let myChart = echarts.init(document.getElementById("YHecharts"));
      let option = {
        title: [
          {
            // text: botName,
            left: "50%",
            bottom: "0px",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        color: [
          "#FF954D",
          "#F5E77C",
          "#26CE93",
          "#22A7FF",
          "#4F62F9",
          "#F95664",
        ],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            data: datas,
            label: {
              show: false,
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    // 错误表述
    async getPlanMisreGroups() {
      const { endTime, startTime } = this;
      try {
        const Res = await getPlanMisreGroups({
          endTime,
          startTime,
        });
        if (Res.data.code == 200 && Res.data.data) {
          const {
            data: { data },
          } = Res;
          this.ifwrongObj = false;
          if (!data.misre || data.misre.length == 0) {
            this.wrongObj = null;
            return;
          }
          this.wrongObj = data;
          const values = data.misre.map((v) => v.value); // x轴文字
          const nums = data.misre.map((v) => v.num); // 柱子的数量
          this.statisticsEchart({ values, nums }, "CWecharts");
        } else {
          this.wrongObj = null;
          this.ifwrongObj = false;
        }
      } catch (error) {
        this.wrongObj = null;
        this.ifwrongObj = false;
      }
    },
    // 监测信息
    async getReportDataInfo() {
      const { endTime, startTime } = this;
      const Res = await getReportDataInfo({ endTime, startTime });
      if (Res.data.code == 200) {
        this.monitorObj = Res.data.data;
      }
    },
  },
  created() {
    // 从移动端获取
    this.endTime = this.$route.query.endTime || "2023-03-13 00:00:00";
    this.startTime = this.$route.query.startTime || "2023-01-10 00:00:00";
    window.localStorage.setItem("token", this.$route.query.token);
  },
  async mounted() {
    await this.$main.mySetTimeOut(500); // 加0.5秒的延迟确保 token已经储存
    this.statistics(); // 发布内容统计(APP统计页面)
    this.harmfulData(); // 有害信息(APP统计页面)
    this.getPlanMisreGroups(); // 错误表述
    this.getReportDataInfo(); // 监测信息
    this.count() // 查询处置已完成的总数
  },
};
</script>

<style lang="scss" scoped>
.report {
  width: 100%;
  min-height: 100vh;
  background: #e4e9f7;
  background-image: url("../../assets/new/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 70vh;
  padding: 0 15px;
  box-sizing: border-box;
  font-family: PingFangSC-Semibold, PingFang SC;
  .logo-img {
    width: 60px;
    margin-top: 40px;
  }
  .title-img {
    width: 215px;
    margin-top: 30px;
  }
  .desc-Box {
    top: 130px;
    color: #ffffff;
    margin-top: 25px;
  }
  .selectBox {
    width: auto;
    padding: 8px 23px;
    background: #ffa240;
    color: #ffffff;
    font-weight: 600;
    border-radius: 27px;
    margin-top: 10px;
    // text-align: center;
  }

  .nav-box {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .nav-item {
      width: 30%;
      padding: 16px 16px 12px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background: #ffffff;
      border-radius: 10px;
      img {
        width: 100px;
        margin: 0 auto;
      }
      .num {
        color: #1a47bc;
        font-weight: 800;
        margin-top: 10px;
      }
      .desc {
        color: #6f7a93;
      }
    }
  }
  .tab-box {
    display: flex;
    border-radius: 10px;
    justify-content: flex-start;
    padding: 16px;
    background: #ffffff;
    margin-top: 20px;
    .tab-item {
      flex: 1;
      text-align: center;
      border-right: 1px solid #e0e4f0;
      .num {
        color: #f58030;
        font-weight: 800;
        font-size: 22px;
      }
      .desc {
        color: #6f7a93;
      }
    }
    .tab-item:last-child {
      border-right: none;
    }
  }
  .echarts-box {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    position: relative;
    padding: 40px 10px 10px 10px;
    box-sizing: border-box;
    // echarts 显示
    .echarts-show {
      width: 100%;
      min-height: 220px;
    }
    // 顶部的黄色模块
    .echarts-absolute {
      position: absolute;
      left: 0;
      top: -30px;
      border-radius: 27px 27px 27px 0;
    }
    // 有害信息和错误表述的nav块
    .echarts-nav {
      width: 100%;
      color: #6f7a93;
      background: #f3f4f7;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      border-radius: 10px;
      div {
        flex: 1;
        border-right: 1px solid #e0e4f0;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div:last-child {
        border-right: none;
      }
    }
    // 黄色的高亮数字
    .num {
      color: #f58030;
      font-size: 22px;
    }
    //标题
    .title {
      color: #333333;
      font-weight: 800;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    // 有害信息的tag
    .tagBox {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      div {
        width: 32%;
        // flex: 1;
        padding: 6px 12px;
        background: #fff4ec;
        color: #fa7a19;
        font-size: 16px;
        text-align: center;
        margin: 0 0 15px 0;
      }
    }
  }
}
</style>